// 公共样式设置
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

// 标题上的线条样式
.blue-line {
    display: flex;
    margin: 25px 0;

    li {
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background-color: #033B84;
        margin-right: 5px;

        &:first-child {
            width: 85px;
        }
    }
}

.mainStyle {
    margin: 0 auto;
}

// 公共的标题样式
.title-com {
    font-size: 20px;
    text-align: center;
    padding-bottom: 20px;

    p {
        color: #033B84;
        font-size: 1em !important;
        line-height: 1em;
        font-weight: 500;
    }

    h3 {
        margin-top: -10px;
        font-size: 1.25em;
        line-height: 1.25em;
        color: #333;
        letter-spacing: 4px;
    }

    .blue-line {
        width: 120px;
        margin: -15px auto 0;
    }
}

// iconStyle
.icon-style {
    font-size: 1em;
}

// 不参与轮播的样式
.no-carousel {
    display: flex;
}

// 轮播的公共样式
.main-carousel {
    margin-top: 3em;
    font-size: 20px;

    .slick-list {
        height: 460px;
    }

    .slick-dots {
        display: flex;

        li {
            width: 16px;
            height: 3px;
            margin-inline: 5px;

            button {
                &:before {
                    content: '';
                    width: 16px;
                    height: 4px;
                    border-radius: 2px;
                    text-align: center;
                    background-color: #b0a0a0;
                }
            }
        }

        .slick-active {
            margin-inline: 7px;

            button {
                &:before {
                    width: 22px;
                    background-color: #033b84 !important;
                }
            }
        }
    }
}

// 屏幕大于 1440px 时应用该样式
@media screen and (min-width: 1441px) {
    .mainStyle {
        max-width: 1440px;
        min-width: 1200px;
    }
}

// 屏幕大于1024或小于1440时应用的样式
@media screen and(min-width:1024px) and(max-width:1440px) {
    .ant-layout-header {
        padding-inline: 10px !important;

        .header-w img {
            height: 30px;
        }

        .header-w .menuStyle .menu-item a {
            margin: 0px 5px;
        }
    }

    .intro-c {

        .intro-t-w,
        .intro-i-w {
            padding: 0 2em !important;
        }
    }

    .gis-c,
    .ml-c,
    .data-c,
    .server-c,
    .doc-c {
        width: 1000px;
    }

    .gis-c {
        .gis-t {
            width: 33% !important;
        }

        .no-carousel {
            width: 67% !important;

            .gis-item {
                width: 50% !important;
            }
        }
    }

    .ml-c {
        .no-carousel {
            .ml-item {
                width: 33.3%;
            }
        }
    }

    .data-c {
        .no-carousel {
            .data-item {
                width: calc(33.33% - 1.5em);
            }
        }

        .data-item {
            padding: .5em !important;
        }
    }

    .server-w {
        .server-t {
            padding: 1em 0 1em;
            margin-bottom: 1em;
        }

        .server-c {
            .no-carousel {
                .server-item {
                    width: calc(33.33% - 1.5em) !important;
                }
            }
        }
    }
}

// 屏幕大于980或小于1024时应用的样式
@media screen and(min-width:980px) and(max-width:1024px) {


    .ant-layout-header {
        padding-inline: 10px !important;

        .header-w img {
            height: 30px;
        }

        .header-w .menuStyle .menu-item a {
            margin: 0px 5px;
        }
    }

    .intro-w {
        .intro-c {
            .intro-t-w {
                .intro-t-img {
                    width: 30%!important;
                    height: auto!important;
                }
            }
        }
    }

    .gis-c,
    .ml-c,
    .data-c,
    .server-c,
    .doc-c {
        width: 800px;
    }

    .ml-c {
        .no-carousel {
            .ml-item {
                width: 50%;
            }
        }
    }

    .gis-c {
        .gis-t {
            width: 50% !important;
        }

        .no-carousel {
            width: 50% !important;

            .gis-item {
                width: 100% !important;
            }
        }
    }

    .data-c {
        .no-carousel {
            .data-item {
                width: calc(50% - 1.5em);
            }
        }

        .data-item {
            padding: .5em !important;
        }
    }

    .server-w {
        .server-t {
            padding: 1em 0 1em;
            margin-bottom: 1em;
        }

        .server-c {
            .no-carousel {
                .server-item {
                    width: calc(50% - 1.5em) !important;
                }
            }
        }
    }

    .footer-w {
        font-size: 18px;
    }
}

@media screen and(max-width:980px) {
    .gis-c,
    .ml-c,
    .data-c,
    .server-c,
    .doc-c {
        width: 600px;
    }
    // 设置公共的按钮样式
    .ant-btn {
        width: 10em!important;
        span {
            font-size: 1em!important;
        }
    }
    .ant-layout-header {
        font-size: 30px!important;
        padding-inline: 20px!important;
        height: 3em!important;
        line-height: 3em;
        .header-w {
            position: relative;
            font-size: 30px!important;
            .menu-btn {
                display: block;
                line-height: 3em;
            }

            .menuStyle {
                position: absolute;
                right: 0;
                top: 2.5em;
                flex-direction: column;
                margin: 0.3px 0;

                .menu-item {
                    background-color: #222;
                    line-height: 1.5em;

                    a {
                        margin: 0 .5em;
                        font-weight: normal;
                        line-height: 1em;
                        &:hover {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }

    .intro-w {
        font-size: 30px;

        .intro-c {
            p {
                font-size: .7em;
                line-height: 2em;
            }
            .intro-t-img{
                display: none;
            }
            .intro-t-w {
                padding: 0 1em;

                
                .intro-item{
                    p{
                        font-size: .9em;
                    }
                }
            }

            .intro-i-w {
                padding: 0 2em;
                flex-wrap: wrap;
                justify-content: space-around;
                margin-top: 0em;
                .intro-item {
                    width: 100%;
                    margin-top: 1em;
                }
            }
        }
    }

    .ml-w {
        font-size: 30px;

        .ml-carousel {
            font-size: 30px;
        }

        .ml-item {
            color: #fff;
            background: #033b84 url('https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/1%20intro/intro_bg_logo_default.png')no-repeat center;
            height: 15em;

            h5 {
                color: #fff;
            }

            .img-b {
                margin-top: 1em;
                margin-bottom: 1em;
            }

            p {
                display: block;
            }

            .ant-btn {
                display: block;
            }
        }
    }

    .gis-w {
        .gis-c {
            font-size: 30px;
            padding: 5em 0 2em;

            .gis-t {
                width: 100% !important;
                padding: 0 !important;
                top: 1em !important;
            }

            .gis-carousel {
                margin-top: 6em;
                font-size: 30px;

                .slick-slide {
                    padding: 0;
                }
            }

            .gis-item {
                height: 15em;

                .img-b {
                    margin-top: .5em;
                    margin-bottom: 1em;
                }
            }
        }
    }

    .data-w {
        font-size: 30px;

        .data-carousel {
            font-size: 30px !important;
        }
    }

    .server-w {
        font-size: 30px;

        .server-t {
            background-size: cover;
            margin-bottom: 1em;
            .server-intro{
                font-size: .8em;
            }
        }

        .server-carousel {
            font-size: 30px;

            .server-item {
                padding: .5em;
            }

        }
    }
    .doc-w {
        font-size: 30px;
        .doc-c {
            width: 100%;
        }
    }

    .mobile-w {
        font-size: 30px;

        .mobile-c {
            flex-direction: column;
            .mobile-app{
                .app-intro{
                    width: 90%;
                }
            }
            .mobile-img {
                display: none;
            }

        }
    }

    .about-w {
        font-size: 30px !important;

        .about-carousel {
            font-size: 30px !important;

            .slick-slider {
                .slick-list {
                    height: 22em !important;
                }
            }

            .about-item {
                span {
                    font-size: .9em;
                }

                p {
                    font-size: .8em;
                }
            }
        }
    }

    .partners-w{
        font-size: 30px!important;
        .partners-carousel{
            .slick-list {
                height: 30em!important;
            }
        }
        .img-w{
            width: 90%!important;
            img{
                width: 100%;
                height: auto;
            }
        } 
    }
    .team-w{
        font-size: 30px!important;
        .team-carousel{
            width:90%;
            .team-item{
                width: 90%;
            }
            .slick-current{
                .team-item{
                    margin-top: 0!important;
                }
                img{
                    width: 7.5em!important;
                } 
                .team-t{
                    height: 18em;
                    margin-top: -4em;
                    background-position: left 2.5em, right 13em;
                    } 
            }
        }
    }
    .ant-layout-footer{
        background-image: url('https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/9%20footer/footer_bg_blue_mobile%402x.png')!important;
    }
    .footer-w {
        font-size: 36px!important;
        flex-direction: column;
        .footer-i{
            width: 100%!important;
        }
    }
}