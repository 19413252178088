.ant-layout-header{
    position: fixed;
    top: 0;
    left: 0;
    z-index: +1;
    width: 100%;
}
.header-w {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    img {
        margin-top: .5em;
        height: 2em;
    }
    // 菜单
    .menuStyle {
        display: flex;
        height: 100%;
        .menu-item {
            position: relative;
            z-index: +1;
            a {
                font-size: .8em;
                font-weight: bold;
                margin: 0 .5em;
                line-height: 3em;
                color: #fff;

                &:hover {
                    border-bottom: .15em solid #fff;
                }

                svg {
                    width: .7em;
                    height: auto;
                    margin-right: .25em;
                }
            }

            .menu-item-option {
                position: absolute;
                top: 2.4em;
                left: .5em;
                background-color: #464747;
                width: 9em;
                max-width: 15em;
                box-shadow: 0px 2px .4em rgba(27, 67, 127, 0.50);
                padding: .25em 0;
                border-radius: .25em;
                display: none;

                li {
                    line-height: 1.5em;
                    text-align: left;
                    box-sizing: border-box;
                    border-left: .15em solid #464747;
                    border-right: .15em solid #464747;

                    &:hover {
                        background-color: #222222;
                        border-left: .15em solid #fff;
                        border-right: .15em solid #fff;
                    }

                    a {
                        line-height: 1.5em;
                        font-size: .7em;
                        font-weight: normal;
                    }
                }
            }
        }
    }
    .menu-btn{
        font-size: 1.2em;
        display: none;
    }
}

// 视频
.video-w {
    width: inherit;
    position: relative;
    text-align: center;
    line-height: 0;
    margin-top: 60px;
    color: #fff;
    video {
        width: 100%;
    }

    .text {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -30%);
        line-height: 120px;

        h1 {
            font-size: 5vw;
            margin: 0;
        }

        h2 {
            font-size: 3vw;
            margin: 0;
        }
    }
}

// 公司介绍css
.intro-w {
    background-color: #f0f6fc;
    background-image: url(https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/1%20intro/intro_bg_downshape_default.png), url(https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/1%20intro/intro_bg_upshape_default.png);
    background-position: left bottom, right top;
    background-repeat: no-repeat, no-repeat;
    box-sizing: border-box;
    font-size: 20px;
    padding: 50px 0 100px;
    color: #333;

    .intro-c {
        text-align: left;
        .intro-t-w,
        .intro-i-w {
            display: flex;
            padding: 0 1em;
        }
        h2,
        h3,
        h4 {
            line-height: 0px;
        }

        h2 {
            font-size: 2.4em;
            letter-spacing: .25em;
        }

        h3 {
            font-size: 1.5em;
        }

        h4 {
            color: #033B84;
        }

        p {
            font-size: .8em;
            line-height: 1.6em;
            margin: 1.5em 0;
        }

        .intro-t-w {
            .intro-t {
                margin-left: 1em;
                margin-top: 1.5em;
            }
            .intro-t-img{
                width: 35%;
                height: auto;
            }
            .intro-item {
                display: flex;
                align-items: center;

                img {
                    width: 2.5em;
                    height: 2.5em;
                    margin-right: .5em;
                }

                h4 {
                    line-height: .5em;
                    margin: .5em 0;
                }

                p {
                    font-size: .7em;
                    margin: .5em 0;
                    line-height: 1.6em;
                }
            }
        }

        .intro-i-w {
            margin-top: 2.5em;
            padding: 0 1em;
            .intro-item {
                padding: 1em;
                line-height: 0;
                background-color: #fff;
                overflow: hidden;
                position: relative;

                h5 {
                    font-size: .9em;
                }

                span {
                    font-size: .6em;
                    color: #033B84;
                    font-weight: 500;
                }

                p {
                    font-size: .7em;
                    line-height: 1.5em;
                }

                &:hover {
                    background: #033b84 url('https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/1%20intro/intro_bg_logo_default.png')no-repeat center;
                    color: #fff;
                    box-shadow: 1px 1px .4em rgba(27, 67, 127, 0.80);
                    z-index: +1;

                    // img {
                    //     transform: translateY(-200px);
                    //     -webkit-filter:drop-shadow(#fff 0 200px);
                    //     -moz-filter:drop-shadow(#fff 0 200px);
                    //     -ms-filter:drop-shadow(#fff 0 200px);
                    //     -o-filter:drop-shadow(#fff 0 200px);
                    //     filter: drop-shadow(#fff 0 200px);
                    // }

                    span {
                        color: #fff;
                    }
                }
            }
        }
    }
}

// 机器学习css
.ml-w {
    background-color: #fff;
    font-size: 20px;
    .ml-c {
        box-sizing: border-box;
        padding: 2em 0;
    }
    .no-carousel{
        width: inherit;
        flex-wrap: wrap;
        justify-content: center;
    }
    .ml-carousel{
        margin-top: 0;
        font-size: 20px;
        .slick-slide {
            .slick-list {
                height: 23em;
            }
            .ml-item {
                width: 100%;
                margin: 0;
            }
        }
    }
    .ml-item {
        width: 25%;
        height: 20em;
        padding: 1.5em;
        border-radius: .5em;
        h5 {
            color: #033B84;
            line-height: 0;
            font-size: 0.9em;
        }

        .img-b {
            overflow: hidden;
            width: 5em;
            height: 5em;
            background-color: #fff;
            border-radius: 2.5em;
            box-shadow: 0px 0px 1em .2em rgba(0, 0, 0, 0.1);
            margin-left: 50%;
            transform: translateX(-50%);
            margin-top: 2em;
            margin-bottom: 2em;

            img {
                margin: auto;
                margin-top: 50%;
                transform: translateY(-50%);
            }
        }

        p {
            font-size: .7em;
            line-height: 2em;
        }

        .ant-btn {
            display: none;
            color: #033B84;
            width: 8em;
            margin: 0 auto;
        }

        &:hover {
            color: #fff;
            background: #033b84 url('https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/1%20intro/intro_bg_logo_default.png')no-repeat center;

            h5 {
                color: #fff;
            }

            p {
                display: none;
            }

            .ant-btn {
                display: block;
            }
        }
    }
}

// 地理信息
.gis-w {
    background: #fff url('https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/3%20gis/gis_bg_earth_default.png') no-repeat;
    // background-size: auto 100%;
    background-position: center;
    background-size: cover;
    font-size: 20px;
    .gis-c {
        box-sizing: border-box;
        position: relative;
        padding: 5em 0 2em;

        .gis-t {
            width: 25%;
            color: #fff;
            text-align: left;
            line-height: 1em;
            position: absolute;
            top: 5em;
            right: 0;
            padding: 0 1.5em;

            span {
                font-weight: 500;
            }

            h2 {
                font-size: 1.5em;
                margin: .75em 0;
                letter-spacing: .15em;
            }

            p {
                font-size: .7em;
                line-height: 1.5em;
                color: #DCDCDC;
            }

            .blue-line {
                li {
                    background-color: #fff;
                }
            }
        }

        // 不参与轮播的样式
        .no-carousel {
            width: 75%;
        }

        .gis-item {
            background-color: rgba(255, 255, 255, .9);
            width: 30%;
            height: 20em;
            box-sizing: border-box;
            padding: 1.5em;
            text-align: center;
            border-radius: .5em;
            margin: 0 1.5em;
            position: relative;
            .img-b {
                overflow: hidden;
                width: 5em;
                height: 5em;
                background-color: #033b84;
                border-radius: 2.5em;
                margin-left: 50%;
                transform: translateX(-50%);
                margin-top: 1.5em;
                margin-bottom: 2em;

                img {
                    margin: auto;
                    margin-top: 50%;
                    transform: translateY(-50%);
                }
            }

            h5 {
                color: #033B84;
                line-height: 0;
                font-size: 0.9em;
            }

            p {
                font-size: .7em;
                line-height: 1.6em;
            }

            .ant-btn {
                width: 8em;
                position: absolute;
                left: 50%;
                bottom: 2.5em;
                color: #fff;
                transform: translateX(-50%);
                background-color: #033b84;
            }

            &:hover {
                box-shadow: 0px 0px .6em .2em #033B84;
            }
        }

        // 参与轮播的样式
        .gis-carousel {
            font-size: 20px;
            .slick-slide {
                padding: 0 1.5em;
                .gis-item {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }
}

// 数据展示
.data-w {
    font-size: 20px;
    background-image: url(https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/4%20data/data_bg_downshape_default.png), url(https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/4%20data/data_bg_upshape_default.png);
    background-position: left bottom, right top;
    background-repeat: no-repeat, no-repeat;

    .data-c {
        padding: 2em 0;
        .no-carousel{
            flex-wrap: wrap;
        }
        .data-item {
            width: calc(25% - 1.5em);
            box-sizing: border-box;
            margin: .75em;
            background-color: #fff;
            background-repeat: no-repeat;
            background-size: 100% auto;
            text-align: center;
            line-height: 1.5em;
            box-shadow: 0px 0px 1em .2em rgba(0, 0, 0, 0.1);
            border-radius: .2em;
            padding: 1em;
            img {
                width: 3.5em;
                height: auto;
                margin: .5em auto;
            }

            h5 {
                font-size: .9em;
                color: #fff;
                margin: 0;
                line-height: .9em;
            }

            p {
                font-size: .7em;
                color: #fff;
                line-height: 1.4em;
                margin: 1em 0 5em 0;
            }

            ul {
                margin-bottom: .5em;

                li {
                    font-size: .7em;
                    line-height: 1.8em;
                }
            }

            .ant-btn {
                color: #fff;
                width: 8em;
                background-color: #033b84;
            }
        }
        .data-carousel {
            font-size: 20px;
            margin-top: 1.5em;
            .slick-list{
                height: 22em;

            }
            .slick-slide {
                padding: 0 .75em;

                .data-item {
                    width: 100%;
                    margin: 0;
                }
            }

            .slick-dots {
                li {
                    button {
                        &:before {
                            background-color: #BDBBBC;
                        }
                    }
                }
            }
        }

        
    }
}

// 开发服务
.server-w {
    font-size: 20px;
    background-color: #fff;
    .server-t {
        background: #fff url('https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/5%20server/server_bg_titlebg_default.png')no-repeat top center;
        background-size: 100% auto;
        padding: 2.5em 0 2.6em;
        margin-bottom: 1em;
        margin-bottom: 2em;
        .title-com {
            p,
            h3 {
                color: #fff;
            }

            li {
                background-color: #fff;
            }
        }

        p {
            color: #fff;
            font-size: .7em;
        }
        .server-intro{
            width: 30em;
            margin: 0 auto;
            line-height: 1.8em;
        }
    }
    .server-item-w{
        font-size: 20px;
        box-sizing: border-box;
        margin-top: 1em;
        .no-carousel{
            flex-wrap: wrap;
            .server-item{
                width: calc(25% - 1.5em);
            }
        }
        .server-item {
            margin: .75em;
            border-left: .2em solid #033B84;
            border-right: .2em solid #033B84;
            border-radius: .2em;
            height: 13em;
            padding: 0 1em;
            text-align: left;
            box-shadow: 0px 0px 1em .2em rgba(0, 0, 0, 0.1);
    
            .flex-layout {
                display: flex;
                padding-top: 1.5em;
    
                .img-w {
                    width: 4em;
                    height: 4em;
                    background-color: #033b84;
                    border-radius: .2em;
                    overflow: hidden;
                    text-align: center !important;
    
                    img {
                        width: 3em;
                        margin: auto;
                        margin-top: .5em;
                    }
                }
    
                .title-l {
                    margin-left: .5em;
    
                    span {
                        color: #033B84;
                        font-weight: 500;
                        font-size: .9em;
                    }
    
                    h5 {
                        font-size: 1em;
                        margin: 0;
                    }
                }
            }
    
            p {
                font-size: .7em;
                line-height: 1.6em;
            }
    
            .ant-btn {
                background-color: #033B84;
                color: #fff;
                width: 8em;
            }
    
            &:hover {
                color: #fff;
                background: #033b84 url('https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/1%20intro/intro_bg_logo_default.png')no-repeat center;
                h5,
                p,
                span {
                    color: #fff!important;
                }
    
                .img-w {
                    background-color: #fff;
    
                    // img {
                    //     transform: translateY(-10em);
                    //     -webkit-filter:drop-shadow(#fff 0 200px);
                    //     -moz-filter:drop-shadow(#fff 0 200px);
                    //     -ms-filter:drop-shadow(#fff 0 200px);
                    //     -o-filter:drop-shadow(#fff 0 200px);
                    //     filter: drop-shadow(#033b84 0 10em);
                    // }
                }
    
                .ant-btn {
                    background-color: #fff;
    
                    span {
                        color: #033B84!important;
                    }
                }
            }
        }
    
        .server-carousel {
            margin-top: .75em;
            .slick-list {
                height: 14em;
            }
        }
    }
    
}

// 文档中心
.doc-w {
    font-size: 20px;
    padding: 2em 0;
    background: #fff url(https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/6%20doc/doc_bg_shape_default.png)no-repeat right bottom;
    .doc-c {
        display: flex;
        justify-content: center;
        text-align: center;
        .doc-item {
            margin-top: 2em;
            padding: 0 2em;
            .img-w {
                display: block;
                width: 5em;
                height: 5em;
                margin: auto;
                border-radius: 2.5em;
                background-color: #fff;
                box-shadow: 0px 0px 1em .2em rgba(0, 0, 0, 0.1);
                overflow: hidden;
                cursor:pointer;
                img {
                    width: 3.4em;
                    height: auto;
                    margin-top: .8em;
                    -webkit-mask: unset;
                    mask: unset;
                    -webkit-mask-size:100% 100%;
                    mask-size:100% 100%;
                }
                &:hover{
                    background-color: #033B84;
                    // img{
                    //     mask-image: linear-gradient(rgb(255,255,255));
                    //     mask-image:iamge(url(''),skyblue);
                    //     transform: translateY(-10em);
                    //     -webkit-filter:drop-shadow(#fff 0 200px);
                    //     -moz-filter:drop-shadow(#fff 0 200px);
                    //     -ms-filter:drop-shadow(#fff 0 200px);
                    //     -o-filter:drop-shadow(#fff 0 200px);
                    //     filter: drop-shadow(#fff 0 10em);
                    // }
                }
            }
            h5{
                color: #033B84;
                font-size: .9em;
            }
            p{
                font-size: .7em;
                color: #616161;
                line-height: 1.6em;
            }

        }
    }
}

// 移动应用  
.mobile-w{
    font-size: 20px;
    background: #033B84 url('https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/7%20mp/mp_bg_cityeye_default.png')no-repeat top center;
    background-size: cover;
    .mobile-c{
        height: inherit;
        display: flex;
        .mobile-app{
            text-align: left;
            color: #fff;
            padding-left: 2em;
            padding-top:1.5em;
            padding-bottom: 1em;
            .mobile-t{
                span{
                    font-size: .7em;
                }
                h3{
                    font-size: 1.5em;
                    margin: 0;
                }
                ul{
                    margin:.5em 0;
                    li{
                        background-color: #fff;
                    }
                }
                
            }
            .mobile-item{
                display: inline-flex;
                padding-top: .5em;
                img{
                    margin-left: -.5em;
                }
                .mobile-t{
                    line-height: 0;
                    display: inline-flex;
                    flex-direction: column;
                    justify-content:space-around;
                    h5{
                        font-size: 1.5em;
                        margin: 0;
                        font-weight: 400;
                    }
                    p{
                        font-size: .9em;
                        margin: 0;
                    }
                }
            }
            .app-intro{
                width: 20em;
                font-size: .7em;
                line-height: 1.8em;
                color: #dcdcdc;
                margin-bottom: 2em;
            }
            .ant-btn{
                width: 8em;
                color: #033B84;
            }
            
        }
        
        
    }
}
// 关于我们
.about-w{
    font-size: 20px;
    padding-top: 2em;
    margin-bottom: .5em;
    .about-c{
        .about-carousel{
            font-size: 20px;
            margin-top: 1em;
            .slick-slider{
                .slick-list{
                    height: 20em;
                }
                .slick-center{
                .about-item{
                    .ant-btn{
                        margin-top: 1em;
                    }
                }
                }
            }
            .slick-dots {
                position: absolute;
                bottom: -.5em;
                }
        }
        .about-item{
            background-color: #fff;
            box-shadow: 0px 0px 1em 0px rgba(0,0,0,0.1);
            margin: 0 .75em;
            padding: .5em;
            text-align: center;
            img{
                width: 100%;
            }
            span{
                font-size: .7em;
                color: #033B84;
                font-weight: 700;
            }
            p{
                font-size: .6em;
                color: #616161;
                line-height: 1.6em;
            }
            .ant-btn{
                width: 8em;
                background-color: #033B84;
                span{
                    font-size: .8em;
                    color: #fff;
                    font-weight: normal;
                }
            }
        }
    }
}
// 合作伙伴
.partners-w{
    background-color: #023b83;
    font-size: 20px;
    padding-bottom: 1em;
    margin-top: .5em;
    .partners-t{
        color: #fff;
        text-align: center;
        padding: .75em 0;
        h4,h5{
            margin: 0;
            font-weight: 500;
        }
        h5{
            font-size: .9em;
        }

        h4{
            font-size: 1em;
        }
    }
    .partners-carousel{
        .slick-list {
            height: 18em;
        }
        .slick-dots {
            position: absolute;
            bottom: 0px;
            .slick-active{
                margin-inline: 7px;
                button{
                    &:before {
                        width: 22px;
                        background-color:#fff!important;
                    }
                }
            }
        }
    }
    .img-w{
        width: 1128px!important;
        height: 312px;
        
        img{
            width: 100%;
            height: auto;
        }
    }
    @media screen and(min-width:1024px) and(max-width:1440px) {
        .img-w{
            width: 96%!important;
            img{
                width: 100%;
                height: auto;
            }
        } 
    }
    @media screen and(min-width:980px) and(max-width:1024px) {
        .img-w{
            width: 96%!important;
            img{
                width: 100%;
                height: auto;
            }
        } 
    }
}
// 核心团队
.team-w{
    font-size: 20px;
    width: 100%;
    margin: 0 auto 1.5em;
    .team-t{
        text-align: center;
        padding: .75em 0;
        h4,h5{
            margin: 0;
            font-weight: 500;
        }
        h5{
            font-size: .9em;
            color: #023b83;
        }
        h4{
            font-size: 1em;
            color: #333;
        }
    }
    .item-c{
        display: flex;
        justify-content: space-between;
    }
    .team-item{
        width: 326px;
        border-radius: .2em;
        padding: .5em;
        text-align: center;
        img{
            width: 7.5em;
            margin: auto;
        }
        .team-t{
        background-color: #fff;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
        margin-top: -4em;
        padding: 5em 1em 1em;
        height: 18em;
        background-image: url(https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/8%20aboutus/aboutus_img_%E2%80%9C_default.png), 
        url(https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/8%20aboutus/aboutus_img_%E2%80%9D_default.png);
        background-position: left 2.5em, right 13em;
        background-repeat: no-repeat, no-repeat;
        p{
            color: #616161;
            font-size: .7em;
            line-height: 1.6em;
        }
        }
    }
    .team-carousel{
        width:1140px;
        margin:0 auto;
        .slick-list{
            padding: 1em 2.5em 0em!important;
        }
        .slick-current{
            .team-item{
                margin-top: -1.5em;
            }
            img{
                width: 8em;
            }  
        .team-t{
            height: 20em;
            background-position: left 2.5em, right 15em;
            }
        }
    }
    @media screen and(min-width:980px) and(max-width:1141px) {
        .team-item{
            width: 290px;
        }
        .team-carousel{
            width:960px;
            .slick-list{
                padding: 1em 1.5em 0em!important;
            }
            .slick-current{
                .team-item{
                    margin-top: 0!important;
                }
                img{
                    width: 7.5em!important;
                } 
                .team-t{
                    height: 18em;
                    margin-top: -4em;
                    background-position: left 2.5em, right 13em;
                    } 
            }
        }
    }
}
// footer style
.ant-layout-footer{
    padding: 1em 0;
    background: #023b83 url('https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/9%20footer/footer_bg_blue_default.png')no-repeat top center!important;
    background-size: 100% 100%!important;
    font-size: 20px!important;
    color: #fff!important;
    text-align: left;
    .footer-w{
        display: flex;
        padding: 1em 0;
        border-bottom: 1px solid #fff;
        justify-content: space-between;
        h5{
            font-size: .9em;
            font-weight: 500;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                left: 0;
                bottom: -.3em;
                width: 1.6em;
                height: .1em;
                background-color: #fff;
            }
        }
        .footer-i{
            width: 33%;
        }
        .footer-reco{
            .company-logo{
                display: flex;
                align-items: flex-end;
                line-height: 0;
                p{
                    font-size: .8em;
                    &:last-child{
                        margin-top: 1.5em;
                        margin-bottom: .5em;
                    }
                }
            }
            p{
                font-size: .65em;
            }
        }
        .footer-intro{
            ul{
                li{
                    display: flex;
                    align-items: center;
                    b,p{
                        font-size: .7em;
                        line-height: 0;
                        margin-left: .5em;
                    }
                    b{
                        font-weight: 600;
                    }
                    p{
                        margin-top: .6em;
                    }
                }
            }
        }
        .footer-sub{
            width: 26%;
            p{
                font-size: .7em;
            }
        }
    }
    
}
.copyright{
    text-align: center;
    font-size: .7em;
    a{
        text-decoration: revert;
    }
}
::-webkit-scrollbar {
	width:4px;
	height: 4px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
-webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
border-radius:10px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
border-radius:10px;
background:rgba(0,0,0,0.2);
-webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
}